<template>
    <div class="card" style="min-height: 200px">
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th>Invoice No</th>
                    <th>Invoice Date</th>
                    <th>Patient Details</th>
                    <th>Consultant</th>
                    <th>Admission No</th>
                    <th>Word/Bed/Cabin</th>
                    <th>Unit</th>
                    <th class="text-right">Bill Amount</th>
                    <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, i) in purchase" :key="i">
                    <td> {{ item.bill_number }}</td>
                    <td> {{ item.date }}</td>
                    <td style="width: 15%">
                        <p style="font-weight: bold">{{ item.party_name }}</p>
                        <p>{{getPartyInfo(item.contact, 'mobile_no')}}</p>
                    </td>
                    <td>
                        {{ item.ipd_register && item.ipd_register.human_resource && item.ipd_register.human_resource.name }}
                    </td>
                    <td> {{ item.ipd_register && item.ipd_register.admission_no }}</td>
                    <td>
                        {{ item.ipd_register && item.ipd_register.service_resource && item.ipd_register.service_resource.name }}
                    </td>
                    <td> {{ item.business && item.business.name }}</td>
                    <td class="text-right"> {{ item.bill_amount }}</td>
                    <td>
                        <button class="btn btn-secondary btn-sm"
                                @click="goToPrint(item.id, 1)"
                                style="cursor: pointer; margin-left: 10px"
                        >
                          <i class="fas fa-print"></i>
                        </button>
                    </td>
                </tr>

                </tbody>
            </table>
            <p v-if="!purchase.length" class="text-center mt-5">No data</p>
        </div>
    </div>
</template>

<script>
import figureFormatter from '@/services/utils/figureFormatter';
import handlePurchase from "@/services/modules/purchase";
import pdfPrinter from '@/services/utils/pdfPrinter';
import handleCompany from "@/services/modules/company";
import handleBarcode from "@/services/modules/barcode";
import { useStore } from "vuex";
import hospitalPdfPrinterHelper from "@/services/utils/hospitalPdfPrinterHelper";

export default {  
    name: 'HospitalListTable',
    data() {
        return{
            companyInfo: {},
            invoice: {},
            barcodeQuery: '',
            barcode: '',
            user: {},
            invoiceRes: {},
            qrcode: ''
        }
    },
    props: {
        purchase: Array,
        route_name: {
            type: String,
            default: 'purchase-bill-edit'
        },
    },
    setup() {
        const {commaFormat} = figureFormatter()
        const { fetchSalesBill } = handlePurchase();
        const {fetchCompanyInfo} = handleCompany();
        const { exportToPDF } = pdfPrinter();
        const { fetchBarcode } = handleBarcode();
        const store = useStore();
        const { generatePdf } = hospitalPdfPrinterHelper();

        return {
            commaFormat,
            fetchSalesBill,
            exportToPDF,
            fetchCompanyInfo,
            fetchBarcode,
            generatePdf,
            store
        }
    },
    mounted() {
         this.fetchCompanyInfo(this.$route.params.companyId).then( (res) => {
            this.companyInfo = res.data;
         }).catch((error) => {
            console.log(error);
         });

         this.user = this.store.state.user.user
    },
    methods: {
        goToPrint(id) {
            const companyId = this.$route.params.companyId
            const companyQuery = `?company_id=${companyId}`;

            this.fetchSalesBill(id, companyQuery).then( (res) =>{
                if( res ){
                    this.invoiceRes = res.data;
                }
            }).then( () => {
                this.generatePdf(this.companyInfo, this.invoiceRes, 'BILL', true, true)
            }).catch( (err) => {
                console.log(err);
            })
        },
        formatDate(date) {
            var formattedDate = date.toISOString().split('T')[0];
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var amOrPm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12 || 12;
            var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;

            return this.formatToDdMmYy(formattedDate) + ' ' + formattedTime
        },
        formatToDdMmYy(date) {
            var dat = date.split('-');
            return `${dat[2]}-${dat[1]}-${dat[0]}`;
        },
        getPartyInfo(contact, fieldName) {
            if (contact && contact.profile_items) {
                const field = contact.profile_items.find(item => item.field === fieldName)
                return field && field.value
            }
            return ""
        }
    }
}
</script>
